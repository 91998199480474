import React from 'react';
import Card from './Card'; // Importa el componente Card
import img from '../img/tarjeta.jpg'; // Importa las imágenes para las tarjetas
import imgg from '../img/tarjetaa.jpg';
import imggg from '../img/calidad.jpg';
import imgggg from '../img/tarjetaaaa.jpg';
import imggggg from '../img/tarjetaaaaa.jpg';
import imgggggg from '../img/socios.jpg';

// Datos de ejemplo para las tarjetas
const cardsData = [
  {
    id: 1,
    title: "Enfoque Holístico",
    content: "Ciberseguridad e Inteligencia artificial, pilares en la oferta a nuestros clientes, estamos concentrado en incrementar la productividad a la vez que reducimos los riesgos de seguridad.",
    imgSrc: img, // Imagen para la primera tarjeta
  },
  {
    id: 2,
    title: "Asesoría y soporte continuo",
    content: "Nuestros productos y servicios incluyen soporte y asesorías para optimizar la inversión de nuestros clientes.",
    imgSrc: imgg, // Imagen para la segunda tarjeta
  },
  {
    id: 3,
    title: "Calidad en la entrega",
    content: "Usamos metodologías probadas para la gestión de incidentes, requerimientos y proyectos, que permiten medir cada etapa en el proceso de despliegue y corregir las desviaciones.",
    imgSrc: imggg, // Imagen para la tercera tarjeta
  },
  {
    id: 4,
    title: "Aplicación de mejores prácticas",
    content: "Las recomendaciones se basan en un análisis de la situación de ciberseguridad, pero aplicar mejores prácticas es clave para reducir riesgos.",
    imgSrc: imgggg, // Imagen para la cuarta tarjeta
  },
  {
    id: 5,
    title: "Relación Ganar - Ganar",
    content: "Creamos relaciones a largo plazo, basadas en la confianza y transparencia, donde el retorno de su inversión en ciberseguridad es totalmente medible en la reducción de riesgos.",
    imgSrc: imggggg, // Imagen para la quinta tarjeta
  },
  {
    id: 6,
    title: "Socios estratégicos",
    content: "Estamos a tu lado, apoyamos tu estrategia de negocio, nos adaptamos a tus necesidades y crecemos juntos.",
    imgSrc: imgggggg, // Imagen para la sexta tarjeta
  },
  // ...otros objetos para cada tarjeta
];

// Componente que muestra la propuesta de valor con tarjetas
export const Propuesta = () => {
  return (
    <div className="bg-[#061C5B] p-10">
      {/* Título de la sección */}
      <h2 className="text-4xl font-bold text-center text-white mb-10">PROPUESTA DE VALOR</h2> 
      {/* Línea divisoria */}
      <div className='linea-dos'></div> 
      {/* Renderiza cada tarjeta con sus datos */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 text-center">
        {cardsData.map(card => (
          <Card key={card.id} title={card.title} content={card.content} imgSrc={card.imgSrc} /> 
        ))}
      </div>
    </div>
  );
};

export default Propuesta;
