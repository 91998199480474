import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export const Faq = () => {
  // Estado para controlar qué pregunta está activa
  const [activeIndex, setActiveIndex] = useState(null);

  // Array de preguntas frecuentes y respuestas
  const faqs = [
    {
      question: '¿Pueden brindarme todas estas soluciones con un solo servicio?',
      answer: 'Totalmente, una vez identificados las soluciones que se ajusten a tu empresa y presupuesto, se entregan como un servicio que puede ser pagado mensual o anualmente.'
    },
    {
      question: '¿Pueden medir el retorno de la inversión?',
      answer: 'Así es, nuestros reportes mensuales les permiten identificar todos los casos detectados y contenidos y cual hubiese sido el impacto de no haberlo hecho.'
    },
    {
      question: '¿La evaluación inicial tiene costo?',
      answer: 'La evaluación es un servicio de valor agregado que nos permite entender mejor a tu empresa, así como demostrarles que nivel de riesgo están asumiendo, de esta manera, en conjunto trabajar en la reducción de estos riesgos.'
    },
    // ...otros elementos FAQ
  ];

  // Función para alternar el estado de la pregunta activa
  const toggleFAQ = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="bg-white p-8 flex justify-center h-screen">
      <div className="w-full max-w-4xl grid justify-items-center">
        <h2 className="text-4xl font-bold text-center mb-4 text-black">PREGUNTAS FRECUENTES</h2>
        <p className="text-center mb-8">Comunícate con nosotros si no puedes encontrar una respuesta a tu pregunta.</p>
        {/* Mapeo de preguntas frecuentes */}
        {faqs.map((faq, index) => (
          <div key={index} className="">
            <div
              className={`p-4 bg-blue-600 text-white rounded-full shadow-lg cursor-pointer mx-auto w-full md:w-3/4 ${activeIndex === index ? 'bg-blue-700' : ''}`}
              onClick={() => toggleFAQ(index)}
            >
              <h3 className="font-semibold">{faq.question}</h3>
            </div>
            {/* Contenido de respuesta */}
            <div
              className={`overflow-hidden transition-all duration-200 ease-in-out ${
                activeIndex === index ? 'max-h-[1000px] ' : 'max-h-0'
              }`}
            >
              <div className="p-4 rounded-sm bg-blue-200 text-black mx-auto w-full md:w-3/4">{faq.answer}</div>
            </div>
          </div>
        ))}
        {/* Botón para enviar solicitud */}
        <div className="text-center mt-8">
          <Link to={'/consultas'}>
            <button className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-blue-700">
              Envíanos tu solicitud
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Faq;
