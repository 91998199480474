import React from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useInView } from 'react-intersection-observer'; // Importamos useInView
import 'animate.css'; // Importa animate.css para las animaciones

import imagen1 from '../img/Tixass.avif';
import imagen2 from '../img/cardd.avif';
import imagen3 from '../img/iso.avif';
import imagen4 from '../img/cardddd.avif';

// Componente para cada tarjeta de consultoría
const ConsultoriaCard = ({ consultoria, index }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const handleOpenModal = () => {
    Swal.fire({
      title: consultoria.title,
      text: consultoria.description,
      imageUrl: consultoria.imagen,
      imageWidth: 800,
      imageHeight: 300,
      showCloseButton: true,
      imageAlt: 'Consultoría Imagen',
      width: 1000,
      color: "black",
      focusConfirm: false,
      footer: `<a href='./servicios/formulario-${consultoria.title.replace(/\s+/g, '-').toLowerCase()}' class='bg-blue-500 text-white py-2 px-4 rounded-md border-transparent hover:bg-blue-600 transition-colors duration-300 ml-4'>Más Información</a>`,
      showConfirmButton: false,
    });
  };

  return (
    <div
      ref={ref}
      className={`consultoria-card bg-white rounded-lg shadow-md p-4 transition-transform duration-500 ease-out ${inView ? 'animate__animated animate__zoomIn' : ''}`}
      style={{ animationDelay: `${index * 0.3}s` }}
    >
      <div className="relative">
        <h2
          onClick={handleOpenModal}
          title='Ver'
          className="cursor-pointer absolute inset-x-0 bottom-0 text-white bg-black bg-opacity-75 p-2 h-28 text-3xl"
        >
          {consultoria.title}
        </h2>
        <img
          onClick={handleOpenModal}
          title='Ver'
          src={consultoria.imagen}
          alt={consultoria.title}
          className="rounded-md mb-4 imagen-consultoria cursor-pointer"
        />
      </div>
      <Link
        to={`./formulario-${consultoria.title.replace(/\s+/g, '-').toLowerCase()}`}
        className="bg-blue-500 text-white py-2 px-4 rounded-md border-transparent hover:bg-blue-600 transition-colors duration-300 mt-4 block text-center"
      >
        Más Información
      </Link>
    </div>
  );
};

const Consultoria = () => {
  const consultorias = [
    { id: 1, imagen: imagen1, title: 'Etiqueta Tisax', description: 'Trabajamos en conjunto para que tu empresa obtenga la etiqueta TISAX, nuestros servicios incluyen: levantamiento de información, diseño y documentación, generación de formatos para evidencias, entrenamiento al equipo participante, apoyo y acompañamiento en las auditorías internas y auditorias con el ente oficial.​' },
    { id: 2, imagen: imagen2, title: 'Servicio de CISO', description: 'Ofrecemos un servicio de consultoría en ciberseguridad que proporciona a su empresa un CISO (Chief Information Security Officer) altamente experimentado y líder durante un período específico y para proyectos particulares. Nuestros consultores cuentan con una amplia experiencia en diversos sectores, incluyendo el financiero, retail, manufactura y automotriz.' },
    { id: 3, imagen: imagen3, title: 'Certificación ISO27001', description: 'Apoyamos a tu empresa en la certificación ISO 27001 o, si prefieres, te ayudamos a implementar las mejores prácticas sin la necesidad de certificación. Nos encargamos de definir y documentar al menos 18 procesos clave para asegurar que estés alineado con los estándares de la ISO 27001.' },
    { id: 4, imagen: imagen4, title: 'Certificación ISO22301', description: 'Ofrecemos apoyo integral en la gestión de la continuidad del negocio, incluyendo la comprensión y aplicación de una estrategia de continuidad de negocios, la elaboración del Análisis de Impacto al Negocio (BIA), la definición de un plan de recuperación ante desastres (DR) y la generación de formatos para cada actividad. Además, proporcionamos entrenamiento al equipo participante y diseñamos ejercicios de gestión de crisis, entre otras actividades relacionadas para garantizar la resiliencia de su empresa frente a situaciones adversas.' }
  ];

  return (
    <div className="text-center p-20">
      <h2 className="text-4xl font-bold">CONSULTORÍA ESPECIALIZADA</h2>
      <div className='linea mt-8'></div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {consultorias.map((consultoria, index) => (
          <ConsultoriaCard key={consultoria.id} consultoria={consultoria} index={index} />
        ))}
      </div>
    </div>
  );
};

export default Consultoria;
