import React from 'react';
import { motion } from 'framer-motion'; // Importa motion de framer-motion para animaciones
import { Link } from 'react-router-dom'; // Importa Link desde react-router-dom para navegación
import { useInView } from 'react-intersection-observer'; // Importa useInView para detectar la visibilidad de los elementos
import empresaImage from '../img/basadaa.png'; // Importa la imagen de la empresa
import facebook from '../img/facebook.png'; // Importa la imagen de Facebook
import whatsapp from '../img/whatsapp.png'; // Importa la imagen de WhatsApp

const Informacion = () => {
    const [ref, inView] = useInView({
        triggerOnce: true, // Solo se activará una vez cuando el elemento esté en la vista
        threshold: 0.5, // Define en qué punto el elemento se considera visible
    });

    const containerVariants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
    };

    return (
        <div className="container mx-auto flex flex-col lg:flex-row justify-center items-center p-8 fondo">
            {/* Contenedor a la izquierda */}
            <div className="w-full lg:w-[50%] lg:h-[600px] h-[100px] mb-10 lg:mb-0 lg:mt-20 flex justify-center items-center pr-8 bg-cover bg-center md:mr-12 rounded"
                style={{backgroundImage: `url(${empresaImage})`}}
            >
             </div>

            {/* Contenedor a la derecha */}
            <motion.div
                ref={ref}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={containerVariants}
                transition={{ duration: 0.6, delay: 0.2 }}
                className="relative w-full lg:w-[50%] flex flex-col justify-center items-center lg:items-start text-white"
            >
                
                {/* Primer contenedor */}
                <motion.div
                    className="p-8 rounded-lg shadow-md mb-4 w-full lg:w-[80%] bg-[#0e0f52]"
                    variants={containerVariants}
                >
                    <h2 className="text-3xl font-bold mb-4">Información de Contacto</h2>
                    {/* <p className="mb-2 text-2xl"><strong>Dirección:</strong> Calle Nombre, Ciudad, País</p> */}
                    <p className="mb-2 text-2xl"><strong>Horario de Atención:</strong> Lunes a Viernes, 9am - 5pm</p>
                </motion.div>
                {/* Segundo contenedor */}
                <motion.div
                    className="bg-[#0e0f52] p-8 rounded-lg shadow-md w-full lg:w-[80%] relative"
                    variants={containerVariants}
                >
                    <h2 className="text-3xl font-bold mb-4">Contacto</h2>
                    <p className="mb-2 text-2xl"><strong>Teléfono:</strong> +52 221 522 8483</p>
                    <p className="mb-4 text-2xl "><strong>Correo Electrónico:</strong> gestion@dscyber.io</p>
                    {/* Agregar enlaces a las imágenes con efecto de crecimiento en hover */}
                    <div className="flex justify-center lg:absolute lg:bottom-0 lg:right-0 mb-4 mr-4">
                        <a href="https://wa.me/522215228483" target="_blank" rel="noopener noreferrer" className="">
                            <motion.img
                                src={whatsapp}
                                alt="Whatsapp"
                                className="w-8 h-8 hover:scale-110 transition-transform duration-300"
                                whileHover={{ scale: 1.1 }}
                            />
                        </a>
                        <a href="https://www.facebook.com/dscyber.io/" target="_blank" rel="noopener noreferrer" className="ml-4">
                            <motion.img
                                src={facebook}
                                alt="Facebook"
                                className="w-8 h-8 hover:scale-110 transition-transform duration-300"
                                whileHover={{ scale: 1.1 }}
                            />
                        </a>
                        
                    </div>
                    {/* Agregar un enlace al formulario de consultas */}
                    <Link to="/consultas" className="bg-blue-500 text-white py-2 px-4 rounded-md border-transparent hover:bg-blue-600 transition-colors duration-300">Consultar</Link>

                </motion.div>
                
            </motion.div>
        </div>
    );
};

export default Informacion;
