import React from 'react'
import Informacion from '../component/Informacion';
 
 
export const Contacto = () => {
  return (
    <div>

 <Informacion/>

    </div>
  )
}


export default Contacto;