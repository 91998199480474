import React, { useRef } from 'react';
import { useForm } from '@formspree/react'; // Importación del hook useForm de formspree para manejar el formulario
import Swal from 'sweetalert2'; // Importación de SweetAlert2 para mostrar alertas personalizadas
import ReCAPTCHA from "react-google-recaptcha"; // Importación de ReCAPTCHA para agregar la funcionalidad de CAPTCHA al formulario
import isoImage from '../img/basadaa.png'; // Importación de la imagen que se muestra en el formulario

export const FormularioGeneral = () => {
  const [state, handleSubmit] = useForm("manwdryr");
  const recaptchaRef = useRef(); // Referencia al componente ReCAPTCHA para acceder a sus métodos

  // Función para manejar el envío del formulario
  const handleFormSubmit = async (e) => {
    e.preventDefault(); // Prevenir el comportamiento predeterminado del formulario
    const recaptchaValue = recaptchaRef.current.getValue(); // Obtener el valor del ReCAPTCHA
    if (!recaptchaValue) { // Verificar si el ReCAPTCHA no ha sido completado
      Swal.fire({ // Mostrar alerta con SweetAlert2 indicando que el ReCAPTCHA no ha sido completado
        icon: 'warning',
        title: 'Captcha no completado',
        text: 'Por favor, completa el captcha antes de enviar el formulario.',
      });
      return; // Salir de la función si el ReCAPTCHA no ha sido completado
    }
    handleSubmit(e); // Llamar a la función para manejar el envío del formulario de formspree
    e.target.reset(); // Resetear los campos del formulario después de enviar
  };

  // Verificar si el estado del formulario indica que el envío fue exitoso y mostrar una alerta
  if (state.succeeded) {
    Swal.fire({
      icon: 'success',
      title: 'Enviado',
      text: 'Tu pregunta o consulta ha sido enviada con éxito. Te responderemos lo antes posible.',
    });
  }

  return (
    <div className="flex flex-col lg:flex-row bg-blue-500">
      {/* Sección de la imagen a la izquierda */}
      <div className="w-full lg:w-1/2 flex justify-center items-center p-5">
        <img src={isoImage} alt="Imagen" className="w-full  rounded-2xl" />
      </div>
      {/* Sección del formulario a la derecha */}
      <div className="w-full lg:w-1/2 p-6 fondoFormulario">
        <h2 className="text-3xl font-bold mb-4 text-center text-white mt-10">Formulario de Consultas</h2>
        <form onSubmit={handleFormSubmit} method="POST"  className='bg-blue-400 rounded-2xl p-5'>
          <input type="hidden" name="Consultas" value="Consulta" /> {/* Campo oculto para identificar el tipo de consulta */}

          {/* Campo para ingresar el nombre */}
          <div className="mb-4">
            <label htmlFor="nombre" className="block mb-2 text-white">Nombre:</label>
            <input type="text" id="nombre" name="nombre" className="border rounded-md px-2 py-1 w-full" required />
          </div>
          {/* Campo para ingresar el correo electrónico */}
          <div className="mb-4">
            <label htmlFor="correo" className="block mb-2 text-white">Correo electrónico:</label>
            <input type="email" id="correo" name="correo" className="border rounded-md px-2 py-1 w-full" required />
          </div>
          {/* Campo para ingresar la pregunta o consulta */}
          <div className="mb-4">
            <label htmlFor="mensaje" className="block mb-2 text-white">Pregunta o consulta:</label>
            <textarea id="mensaje" name="mensaje" className="border rounded-md px-2 py-1 w-full h-32"></textarea>
          </div>
          {/* Componente ReCAPTCHA para verificar que el usuario no es un robot */}
          <div className='flex justify-center m-2'>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Le3lb4pAAAAAIHJ49iYIgs-O4rowqUYrFEkbLVx" // Clave del sitio para el ReCAPTCHA
            />
          </div>
          {/* Botón para enviar el formulario */}
          <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-md border-transparent hover:bg-blue-600 transition-colors duration-300 w-full">Enviar</button>
        </form>
      </div>
    </div>
  );
};

export default FormularioGeneral;