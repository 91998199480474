import React from 'react';
import 'animate.css'; // Importa animate.css para las animaciones
import { useInView } from 'react-intersection-observer'; // Importamos useInView
import { Link } from 'react-router-dom';
import imagenFondo from '../img/uno.jpg';

const InteligenciaArtificialCard = () => {
  // Definimos refs para cada sección que queremos animar
  const [headerRef, inViewHeader] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [descriptionRef, inViewDescription] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [generativeRef, inViewGenerative] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [appliedRef, inViewApplied] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 text-black p-6  bg-cover bg-center"  style={{ 
        backgroundImage: `url(${imagenFondo})`, // Establece la imagen de fondo
        backgroundAttachment: 'fixed' // Añade la propiedad background-attachment
      }}>
      
      <div className="max-w-6xl bg-white rounded-3xl shadow-2xl overflow-hidden transform transition-transform hover:scale-105 duration-300 ease-in-out">
        {/* Contenedor de la Cabecera */}
        <div 
          ref={headerRef} 
          className={`p-8 text-center bg-gradient-to-r from-[#061C5B] to-[#203CE0] ${inViewHeader ? 'animate__animated animate__zoomIn' : ''}`}
        >
          <h1 className="text-4xl font-bold text-white">
            Inteligencia Artificial Empresarial - IA
          </h1>
        </div>

        {/* Contenido Principal */}
        <div className="p-8 space-y-12 text-black">
          {/* Descripción */}
          <div 
            ref={descriptionRef} 
            className={`text-center text-xl ${inViewDescription ? 'animate__animated animate__fadeInUp' : ''}`}
          >
            Nos especializamos en dos áreas de estudio de la IA, ofreciendo una plataforma unificada donde pueden integrar diferentes Modelos de IA (públicos y empresariales) para resolver problemas particulares.
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {/* Sección IA Generativa */}
            <div 
              ref={generativeRef} 
              className={`p-8 rounded-lg shadow-lg bg-white ${inViewGenerative ? 'animate__animated animate__fadeInLeft animate__delay-1s' : ''}`}
            >
              <h2 className="text-2xl font-semibold text-[#061C5B]">IA Generativa</h2>
              <p className="mt-6 text-lg text-black">
                Trabajamos en conjunto para el desarrollo y entrenamiento de asistentes virtuales que pueden interactuar con sus colaboradores, proveedores y clientes en chats o voz para atender requerimientos repetitivos donde el humano no genera valor, permitiendo automatizar varios procesos.
              </p>
            </div>

            {/* Sección IA Aplicada */}
            <div 
              ref={appliedRef} 
              className={`p-8 rounded-lg shadow-lg bg-white ${inViewApplied ? 'animate__animated animate__fadeInRight animate__delay-1s' : ''}`}
            >
              <h2 className="text-2xl font-semibold text-[#061C5B]">IA Aplicada</h2>
              <p className="mt-6 text-lg text-black">
                Este tipo de IA les ayudará al análisis de datos, utilizando algoritmos de ML específicos para atender casos de uso como la generación de forecast de ventas, análisis de documentos para reducir tiempos de revisión, detección de malware, entre otros algoritmos.
              </p>
            </div>
          </div>

          {/* Botón de Acción */}
          <div className="flex justify-center">
            <Link to={'/consultas'}>
              <button className="bg-blue-600 text-white py-3 px-6 rounded-full shadow-md hover:bg-blue-700 transition-all duration-300 transform hover:scale-110 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                Conversemos
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InteligenciaArtificialCard;
