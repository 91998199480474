import React from 'react';

function Plataforma() {
  return (
    <div className='flex justify-center items-center h-screen image text-center'>
      <h2 className='text-3xl text-white'>PLATAFORMA CONVERSACIONAL BASADA EN IA GENERATIVA</h2> {/* Texto que describe la plataforma */}
    </div>
  );
}

export default Plataforma;
