import React from "react";
import Navbar from "../component/Navbar";
import Banner from "../component/Banner";
import Empresa from "../component/Empresa";
import Propuesta from "../component/Propuesta";
import Alianzas from "../component/Alianzas";
import Certificados from "../component/Certificados";
import Faq from "../component/Faq";
// import Clientes from "../component/Clientes";
 
export const Home =()=>{
return(

<div className="">

 <Banner/>
<Empresa/>
<Propuesta/>
{/* <Certificados/> */}
{/* <Clientes/> */}
<Alianzas/>
<Faq/>
 </div>

)



}


export default Home;