import React, { useState } from 'react';
import 'animate.css'; // Importa animate.css para las animaciones
import { FaLightbulb, FaChartLine, FaCog } from 'react-icons/fa'; // Importamos iconos de react-icons
import { useInView } from 'react-intersection-observer'; // Importamos useInView
import { Link } from 'react-router-dom'; // Importamos Link para la navegación interna

const Valores = () => {
  const [inViewRef, inView] = useInView({
    triggerOnce: true, // Solo activa la animación una vez
    threshold: 0.1, // El porcentaje de visibilidad requerido para activar la animación
  });

  return (
    <div className="flex justify-center items-center min-h-screen text-black p-4">
      <div className="max-w-3xl bg-white rounded-lg shadow-2xl overflow-hidden transform transition-transform hover:scale-105 duration-300 ease-in-out">
        <div 
          ref={inViewRef}
          className={`p-8 rounded-lg shadow-md bg-gradient-to-r from-[#061C5B] to-[#203CE0] ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>
          <h3 className="text-2xl font-bold text-white mb-6 text-center">Valores que guían la aplicación de IA en tu empresa:</h3>
          <ul className="space-y-6">
            <li className={`flex items-start space-x-4 ${inView ? 'animate__animated animate__fadeInLeft' : ''}`}>
              <FaLightbulb className="text-4xl text-yellow-400 flex-shrink-0" />
              <div>
                <h4 className="text-xl font-semibold text-white">Taller de alineación estratégica en IA.</h4>
                <p className="text-white">Proporcionamos formación estratégica sobre la implementación de IA en su empresa.</p>
              </div>
            </li>
            <li className={`flex items-start space-x-4 ${inView ? 'animate__animated animate__fadeInLeft animate__delay-1s' : ''}`}>
              <FaChartLine className="text-4xl text-green-400 flex-shrink-0" />
              <div>
                <h4 className="text-xl font-semibold text-white">Presentación ejecutiva sobre IA.</h4>
                <p className="text-white">Ofrecemos presentaciones ejecutivas detalladas sobre las ventajas y aplicaciones de la IA.</p>
              </div>
            </li>
            <li className={`flex items-start space-x-4 ${inView ? 'animate__animated animate__fadeInLeft animate__delay-2s' : ''}`}>
              <FaCog className="text-4xl text-blue-400 flex-shrink-0" />
              <div>
                <h4 className="text-xl font-semibold text-white">Desarrollo de procesos y políticas para IA en tu empresa.</h4>
                <p className="text-white">Ayudamos a desarrollar procesos y políticas eficaces para integrar la IA en sus operaciones.</p>
              </div>
            </li>
          </ul>
          {/* Botón de enlace a /servicios */}
          <div className="text-center mt-8">
            <Link to={'/consultas'}>
              <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors duration-300">
              Conversemos
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Valores;
