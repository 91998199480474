// Carousel.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import video1 from "../img/cardd-banner.webm";
import video2 from "../img/cardd-bannerr.webm";
import video3 from "../img/cardd-banerrr.webm";

const Carousel = () => {
  const [currentVideo, setCurrentVideo] = useState(0);
  const videos = [
    { src: video1, link: '/servicios#ciberseguridad' }, // Enlace correcto
    { src: video2, link: null },
    { src: video3, link: null }
  ];

  const [isFirefox, setIsFirefox] = useState(false);

  useEffect(() => {
    const isFirefoxBrowser = navigator.userAgent.toLowerCase().includes('firefox');
    setIsFirefox(isFirefoxBrowser);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentVideo((prevVideo) => (prevVideo + 1) % videos.length);
    }, 13000);

    return () => clearInterval(intervalId);
  }, [videos.length]);

  return (
    <div className="carousel-container">
      <div
        className="carousel-slide"
        style={{
          transform: `translateX(-${currentVideo * 100}%)`
        }}
      >
        {videos.map((video, index) => (
          <div key={index} className="carousel-video-wrapper" style={{ flex: '0 0 100%' }}>
            {video.link && (isFirefox || index === 0) ? (
              <Link to={video.link} style={{ display: 'block', width: '100%', height: '100%' }}>
                <video autoPlay muted loop playsInline className="carousel-video">
                  <source src={video.src} type="video/webm" />
                  Your browser does not support the video tag.
                </video>
              </Link>
            ) : (
              <video autoPlay muted loop playsInline className="carousel-video">
                <source src={video.src} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        ))}
      </div>

      <button
        className="carousel-btn prev"
        onClick={() => setCurrentVideo((prevVideo) => (prevVideo === 0 ? videos.length - 1 : prevVideo - 1))}
      >
        {'<'}
      </button>

      <button
        className="carousel-btn next"
        onClick={() => setCurrentVideo((prevVideo) => (prevVideo + 1) % videos.length)}
      >
        {'>'}
      </button>
    </div>
  );
};

export default Carousel;
