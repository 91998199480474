import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../img/logo.png'; // Importa la imagen del logo

const NotFoundPage = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen bg-[#0e0f52]">
        <img className='h-40 sombra' src={logo} alt="Logo"/> {/* Muestra el logo */}
      <h1 className="text-6xl font-bold mb-4 text-white">Error 404</h1>
      <p className="text-lg mb-4 text-white">La página que estás buscando no existe.</p>
      <Link to="/" className="text-black bg-[#92c1de] p-4 rounded-2xl hover:scale-110 ">Volver al inicio</Link> {/* Enlace para volver al inicio */}
    </div>
  );
};

export default NotFoundPage;
