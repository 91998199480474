// Servicios.js

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import BannerServicios from "../component/BannerServicios";
import Consultoria from "../component/Consultoria";
import Ciberseguridad from "../component/Ciberseguridad";
import Plataforma from "../component/Plataforma";
import Casos from "../component/Casos";
import InteligenciaArtificial from "../component/InteligenciaArtificial";
import Valores from "../component/Valores";

export const Servicios = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#ciberseguridad") {
      const section = document.getElementById("ciberseguridad");
      if (section) {
        const offset = 100; // Ajusta este valor según sea necesario
        const y = section.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div className="">
      <BannerServicios />
      <Consultoria />
      <div style={{ paddingTop: "100px" }} id="ciberseguridad"> {/* Espaciador */}
        <Ciberseguridad />
      </div>
      <InteligenciaArtificial />
      <Casos />
      <Valores />
    </div>
  );
}

export default Servicios;
