import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTopOnMount = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Cuando el componente se monta, hace scroll al principio de la página
    document.title = getTitleFromPathname(pathname); // Actualiza el título de la página basado en la ruta
  }, [pathname]); // Ejecuta el efecto cuando la ruta cambia

  // Función para obtener el título de la página basado en la ruta
  const getTitleFromPathname = (pathname) => {
    switch (pathname) {
      case '/':
        return 'DSCyber - Inicio';
      case '/servicios':
        return 'DSCyber - Servicios';
      case '/contacto':
        return 'DSCyber - Contacto';
      case '/servicios/formulario-etiqueta-tisax':
        return 'DSCyber - Formulario de Etiqueta Tisax';
      case '/servicios/formulario-servicio-de-ciso':
        return 'DSCyber - Formulario de Servicio de CISO';
      case '/servicios/formulario-certificación-iso27001':
        return 'DSCyber - Formulario de Certificación ISO27001';
      case '/servicios/formulario-certificación-iso22301':
        return 'DSCyber - Formulario de Certificación ISO22301';
      case '/consultas':
        return 'DSCyber - Consultas';
      default:
        return 'Página no encontrada';
    }
  };

  return null; // El componente no renderiza nada, solo realiza el efecto
};

export default ScrollToTopOnMount;
