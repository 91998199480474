import './App.css'; // Importa estilos CSS
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Importa componentes de enrutamiento de React Router

// Importa los componentes de las páginas y los componentes compartidos
import Home from './page/Home';
import Servicios from './page/Servicios';
import Contacto from './page/Contacto';
import Navbar from './component/Navbar';
import Footer from './component/Footer';
import ScrollToTopOnMount from './component/ScrollToTopOnMount';
import FormularioEtiquetaTisax from './Formularios/FormularioEtiquetaTisax';
import FormularioServicioCISO from './Formularios/FormularioServicioCISO';
import FormularioCertificadoISO27001 from './Formularios/FormularioCertificacionISO27001';
import FormularioCertificacionISO22301 from './Formularios/FormularioCertificacionISO22301';
import FormularioGeneral from './component/FormularioGeneral';
import Error from './page/Error';
import Certificado1 from './todosLosCertificados/Certificado1';
import Certificado2 from './todosLosCertificados/Certificado2';
import Certificado3 from './todosLosCertificados/Certificado3';
import Certificado4 from './todosLosCertificados/Certificado4';
import Certificado5 from './todosLosCertificados/Certificado5';
import Certificado6 from './todosLosCertificados/Certificado6';
import Certificado7 from './todosLosCertificados/Certificado7';
import Certificado8 from './todosLosCertificados/Certificado8';
import Certificado9 from './todosLosCertificados/Certificado9';
import Certificado10 from './todosLosCertificados/Certificado10';
import Certificado11 from './todosLosCertificados/Certificado11';
import Certificado12 from './todosLosCertificados/Certificado12';
import Certificado13 from './todosLosCertificados/Certificado13';
import Certificado14 from './todosLosCertificados/Certificado14.js';
import Certificado15 from './todosLosCertificados/Certificado15';

function App() {
  return (
    <Router> {/* Utiliza BrowserRouter para envolver la aplicación */}
      <div className="App"> {/* Contenedor principal de la aplicación */}
        <Navbar/> {/* Renderiza la barra de navegación */}
        <ScrollToTopOnMount/> {/* Renderiza el componente para desplazarse hacia arriba al montar una nueva ruta */}
        
        <Routes> {/* Define las rutas de la aplicación */}
          {/* Rutas para las páginas principales */}
          <Route path="/" element={<Home />} />
          <Route path="/servicios" element={<Servicios/>} />
          <Route path="/Contacto" element={<Contacto/>}/>
          <Route path="*" element={<Error/>} /> {/* Ruta para manejar URLs no encontradas */}

          {/* Rutas para los formularios de servicios */}
          <Route path='/servicios/formulario-etiqueta-tisax' element={<FormularioEtiquetaTisax/>}/>
          <Route path='/servicios/formulario-servicio-de-ciso' element={<FormularioServicioCISO/>}/>
          <Route path='/servicios/formulario-certificación-iso27001' element={<FormularioCertificadoISO27001/>}/>
          <Route path='/servicios/formulario-certificación-iso22301' element={<FormularioCertificacionISO22301/>}/>

          {/* Ruta para consultas generales */}
          <Route path='/consultas' element={<FormularioGeneral/>}/>

{/*Estas son las rutas para los certificados*/}

<Route path='/f4hpb0omog5ce' element={<Certificado1/>}/>
<Route path='/6bvpdt5os05lg' element={<Certificado2/>}/>
<Route path='/6sdxjwe5ma' element={<Certificado3/>}/>
<Route path='/9j4jvj05av' element={<Certificado4/>}/>
<Route path='/16uqyf0yzrz5jt' element={<Certificado5/>}/>
<Route path='/69gr6xvcgtt5al' element={<Certificado6/>}/>
<Route path='/bxfwauv5jl' element={<Certificado7/>}/>
<Route path='/f0c4e2f85cp' element={<Certificado8/>}/>
<Route path='/hp1lotl5jm' element={<Certificado9/>}/>
<Route path='/r9tvfi35ll' element={<Certificado10/>}/>
<Route path='/rrwdn7r5rt' element={<Certificado11/>}/>
<Route path='/u7l6p0gme5ao' element={<Certificado12/>}/>
<Route path='/umfzeiz5rki5ja' element={<Certificado13/>}/>
<Route path='/xhvyoneempz5ag' element={<Certificado14/>}/>
<Route path='/zrro6xh5zc' element={<Certificado15/>}/>



        </Routes>
        
        <Footer/> {/* Renderiza el pie de página */}
      </div>
    </Router>
  );
};

export default App; // Exporta el componente App como predeterminado
