import React from 'react'
import NotFoundPage from '../component/NotFoundPage';

export const Error = ()=> {
  return (
    <div>
        
<NotFoundPage/>

    </div>
  )
}

export default Error;