import React from 'react';

export const Card = ({ title, content, imgSrc }) => {
  return (
    <div className="bg-blue-800 p-6 rounded-lg shadow-md text-white w-3/4 mx-auto transition duration-500 ease-in-out transform hover:scale-105 hover:shadow-lg " style={{ boxShadow: '0 0 10px 6px rgba(0, 0, 0, 0.3)' }}>
      <div className="flex flex-col items-center">
        <img src={imgSrc} alt={title} className="w-full h-25 object-cover mb-4 rounded-lg" />
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
      </div>
      <p>{content}</p>
    </div>
  );
};

export default Card;
