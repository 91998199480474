import React from 'react';

// Importa la imagen de fondo con una ruta absoluta
import imagenFondo from '../img/uno.jpg';

// Componente funcional BannerServicios
const BannerServicios = () => {
  return (
    <div
      className="text-white w-screen h-screen bg-cover bg-center flex flex-col justify-center items-center" // Estilos para el contenedor principal
      style={{ 
        backgroundImage: `url(${imagenFondo})`, // Establece la imagen de fondo
        backgroundAttachment: 'fixed' // Añade la propiedad background-attachment
      }}
    >
      {/* Título */}
      <h2 className='text-center mb-4 md:text-5xl sm:text-4xl text-3xl font-bold'>NUESTROS SERVICIOS</h2>
      
      {/* Descripción */}
      <p className="mb-4 md:text-4xl sm:text-3xl text-2xl">Consultoría Tecnológica</p>
      
      {/* Botón de solicitud de evaluación (comentado) */}
      {/* <button className="bg-blue-500  text-white h-20 w-52 py-1 px-4 rounded-lg border-transparent hover:bg-blue-600 transition-colors duration-300">
        Solicita tu evaluación ¡GRATIS!
      </button> */}
    </div>
  );
};

export default BannerServicios; // Exporta el componente BannerServicios
