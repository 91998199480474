import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import logo from '../logos/LOGOS DS CYBER-07.png';

export const Footer = () => {
  return (
    <footer className="bg-blue-900 text-white p-6">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-8 text-center md:text-left">
        <div>
          <img className='h-16 mx-auto md:mx-0' src={logo} alt="Logo de DS Cyber"/>
          <p>Opera eficientemente</p>
          <p>Máximiza la inversión</p>
          <p>Reduce riesgos.</p>
        </div>
        <div>
          <h4 className="font-bold mb-2">Nosotros</h4>
          <p>La empresa</p>
          <p>Propuesta de valor</p>
        </div>
        <div>
          <h4 className="font-bold mb-2">Productos</h4>
          <p>DSeCOps</p>
          <p>Ciberconciencia</p>
          <p>Validación de controles</p>
          <p>Protección de datos</p>
          <p>Threat Hunting</p>
          <p>Ciberinteligencia accionable</p>
        </div>
        <div>
          <h4 className="font-bold mb-2">Servicios</h4>
          <p>Consultoría especializada</p>
          <p>Certificación</p>
          <p>Servicio de CISO</p>
          <p>Certificación ISO27001</p>
          <p>Certificación ISO22301</p>
        </div>
      </div>
      <div className="border-t border-gray-700 mt-8 py-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* Añade etiquetas <a> para enlaces a las redes sociales */}
          <div className="flex justify-center mb-4 md:mb-0">
            <a href="https://www.facebook.com/dscyber.io/" target='_blanck'>
              <FontAwesomeIcon icon={faFacebook} size="lg" className="mx-2" />
            </a>
            <a href="https://www.linkedin.com/in/ds-cyber-3abab4284/?originalSubdomain=mx" target='_blanck'>
              <FontAwesomeIcon icon={faLinkedin} size="lg" className="mx-2" />
            </a>
            {/* <a href="https://www.instagram.com/">
              <FontAwesomeIcon icon={faInstagram} size="lg" className="mx-2" />
            </a> */}
          </div>
          <p className="mt-4 md:mt-0">Copyright © 2024 DSCyber - All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
