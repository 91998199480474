import React, { useState, useEffect, useRef } from 'react';
import { useForm } from '@formspree/react';
import Swal from 'sweetalert2';
import isoImage from '../img/iso.jpg';
import ReCAPTCHA from "react-google-recaptcha";

export const FormularioCertificacionISO27001 = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    apellido: '',
    correo: '',
    puesto: '',
    telefono: '',
    dudasComentarios: '',
    coordinarLlamada: '',
    region: 'MX' // Región por defecto (México)
  });

  const [state, handleSubmit] = useForm("manwdryr");
  const recaptchaRef = useRef();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (state.succeeded) {
      // Mostrar SweetAlert
      Swal.fire({
        icon: 'success',
        title: 'Enviado',
        text: 'El formulario se ha enviado con éxito.',
      });
      // Limpiar el formulario
      setFormData({
        nombre: '',
        apellido: '',
        correo: '',
        puesto: '',
        telefono: '',
        dudasComentarios: '',
        coordinarLlamada: '',
        region: formData.region // Mantener la región seleccionada después de enviar el formulario
      });
    }
  }, [state.succeeded]);
  const validatePhoneNumber = (phoneNumber) => {
    // Validar el formato del número de teléfono según la región seleccionada
    switch (formData.region) {
      case 'MX':
        // Validar número de teléfono para México (10 dígitos)
        return /^\d{10}$/.test(phoneNumber);
      case 'AR':
        // Validar número de teléfono para Argentina (10 dígitos)
        return /^\d{10}$/.test(phoneNumber);
      case 'US':
        // Validar número de teléfono para Estados Unidos (10 dígitos)
        return /^\d{10}$/.test(phoneNumber);
      case 'CA':
        // Validar número de teléfono para Canadá (10 dígitos)
        return /^\d{10}$/.test(phoneNumber);
      case 'GB':
        // Validar número de teléfono para Reino Unido (10 dígitos)
        return /^\d{10}$/.test(phoneNumber);
      case 'AU':
        // Validar número de teléfono para Australia (10 dígitos)
        return /^\d{10}$/.test(phoneNumber);
      case 'DE':
        // Validar número de teléfono para Alemania (10 dígitos)
        return /^\d{10}$/.test(phoneNumber);
      case 'FR':
        // Validar número de teléfono para Francia (10 dígitos)
        return /^\d{10}$/.test(phoneNumber);
      case 'ES':
        // Validar número de teléfono para España (9 dígitos)
        return /^\d{9}$/.test(phoneNumber);
      // Agrega más casos según las regiones que quieras incluir
      default:
        return true; // Por defecto, se acepta cualquier número de teléfono si no se especifica una región específica
    }
  };
  

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
    const invalidProviders = ['gmail', 'yahoo', 'outlook', 'hotmail']; // Agrega aquí los proveedores no permitidos
    const domain = email.split('@')[1].split('.')[0];
    return emailRegex.test(email) && !invalidProviders.includes(domain);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      // Mostrar mensaje de error si el reCAPTCHA no se ha completado
      Swal.fire({
        icon: 'warning',
        title: 'Captcha no completado',
        text: 'Por favor, completa el captcha antes de enviar el formulario.',
      });
      return;
    }
    if (!validatePhoneNumber(formData.telefono)) {
      // Mostrar mensaje de error si el número de teléfono no es válido
      Swal.fire({
        icon: 'warning',
        title: 'Número de teléfono no válido',
        text: 'Por favor, ingresa un número de teléfono válido para la región seleccionada.',
      });
      return;
    }
    if (!validateEmail(formData.correo)) {
      // Mostrar mensaje de error si el correo electrónico no es válido
      Swal.fire({
        icon: 'warning',
        title: 'Correo no válido',
        text: 'Te aconsejamos que introduzcas un correo electrónico de empresa, para poder enviar tu solicitud.',
      });
      return;
    }
    handleSubmit(e);
  };
  

  return (
    <div className="flex flex-col md:flex-row bg-blue-500">
      {/* Sección de la izquierda (imagen) */}
      <div className="md:w-1/2 fondoFormulario flex items-center p-5">
        <img src={isoImage} alt="Producto" className="w-full rounded-2xl" />
      </div>
      {/* Sección del formulario en la derecha */}
      <div className="md:w-1/2 p-6 fondoFormulario">
        <h2 className="text-3xl font-bold mb-4 text-center text-white mt-10">Formulario para Certificación ISO 27001</h2>
        <form onSubmit={handleFormSubmit} className='bg-blue-400 rounded-2xl p-5'>
          {/* Nombre del producto (campo oculto) */}
          <input type="hidden" name="producto" value="Certificación ISO27001" />
          {/* Nombre */}
          <div className="mb-4">
            <label htmlFor="nombre" className="block mb-2 text-white">Nombre:</label>
            <input type="text" id="nombre" name="nombre" value={formData.nombre} onChange={handleChange} className="border rounded-md px-2 py-1 w-full" required />
          </div>
          {/* Apellido */}
          <div className="mb-4">
            <label htmlFor="apellido" className="block mb-2 text-white">Apellido:</label>
            <input type="text" id="apellido" name="apellido" value={formData.apellido} onChange={handleChange} className="border rounded-md px-2 py-1 w-full" required />
          </div>
          {/* Email */}
          <div className="mb-4">
            <label htmlFor="correo" className="block mb-2 text-white">Correo Electrónico:</label>
            <input type="email" id="correo" name="correo" value={formData.correo} onChange={handleChange} className="border rounded-md px-2 py-1 w-full" required pattern="[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}" title="Por favor, ingresa un correo electrónico válido de empresa." />
          </div>
          {/* Puesto */}
          <div className="mb-4">
            <label htmlFor="puesto" className="block mb-2 text-white">Puesto:</label>
            <input type="text" id="puesto" name="puesto" value={formData.puesto} onChange={handleChange} className="border rounded-md px-2 py-1 w-full" required />
          </div>
          {/* Teléfono */}
          <div className="mb-4">
            <label htmlFor="telefono" className="block mb-2 text-white">Teléfono:</label>
            <select id="region" name="region" value={formData.region} onChange={handleChange} className="border rounded-md px-2 py-1 w-full mb-2">
  <option value="MX">México (+52)</option>
  <option value="AR">Argentina (+54)</option>
  <option value="US">Estados Unidos (+1)</option>
  <option value="CA">Canadá (+1)</option>
  <option value="GB">Reino Unido (+44)</option>
  <option value="AU">Australia (+61)</option>
  <option value="DE">Alemania (+49)</option>
  <option value="FR">Francia (+33)</option>
  <option value="ES">España (+34)</option>
  {/* Agrega más opciones según las regiones que desees incluir */}
</select>

<input
  type="tel"
  id="telefono"
  name="telefono"
  value={formData.telefono}
  onChange={handleChange}
  className="border rounded-md px-2 py-1 w-full"
  required
  maxLength={formData.region === 'MX' ? 10 : 15} // Establecer la longitud máxima del número de teléfono según la región seleccionada
/>
          </div>
          {/* Mensaje */}
          <div className="mb-4">
            <label htmlFor="mensaje" className="block mb-2 text-white">Mensaje:</label>
            <textarea id="mensaje" name="mensaje" value={formData.mensaje} onChange={handleChange} className="border rounded-md px-2 py-1 w-full h-32"></textarea>
          </div>
          {/* Coordinar Llamada */}
          <div className="mb-4">
            <p className="text-white">¿Te gustaría coordinar una llamada con un especialista para profundizar en alguno de estos temas?</p>
            <div className="flex items-center">
              <input type="radio" id="coordinarLlamadaSi" name="coordinarLlamada" value="si" onChange={handleChange} className="mr-2" />
              <label htmlFor="coordinarLlamadaSi" className="text-white mr-6">Sí</label>
              <input type="radio" id="coordinarLlamadaNo" name="coordinarLlamada" value="no" onChange={handleChange} className="mr-2" />
              <label htmlFor="coordinarLlamadaNo" className="text-white">No</label>
            </div>
          </div>
          <div className='flex justify-center m-2'>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6Le3lb4pAAAAAIHJ49iYIgs-O4rowqUYrFEkbLVx"
          />
          </div>
          {/* Botón Enviar */}
          <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-md border-transparent hover:bg-blue-600 transition-colors duration-300 w-full">Enviar</button>
        </form>
      </div>
    </div>
  );
};

export default FormularioCertificacionISO27001;
