import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../logos/LOGOS DS CYBER-07.png';

const Navbar = () => {
    // Estado para controlar si se ha desplazado la barra de navegación
    const [scrolled, setScrolled] = useState(false);
    // Estado para controlar si el menú está abierto o cerrado
    const [menuOpen, setMenuOpen] = useState(false);
    // Hook para obtener la ubicación actual de la página
    const location = useLocation();
    // Referencia al elemento del menú para detectar clicks fuera de él
    const menuRef = useRef(null);

    // Efecto para manejar el desplazamiento de la ventana
    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 50; // Comprueba si la ventana se ha desplazado más de 50 píxeles
            if (isScrolled !== scrolled) {
                setScrolled(isScrolled); // Actualiza el estado scrolled
            }
        };

        const handleClickOutside = (event) => {
            // Cierra el menú si se hace clic fuera de él
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        };

        // Agrega event listeners para el desplazamiento de la ventana y los clicks fuera del menú
        window.addEventListener('scroll', handleScroll);
        document.addEventListener('mousedown', handleClickOutside);

        // Limpia los event listeners cuando el componente se desmonta
        return () => {
            window.removeEventListener('scroll', handleScroll);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [scrolled]);

    // Determina el color de fondo de la barra de navegación basado en si se ha desplazado o no
    const bgColor = scrolled ? 'rgba(6, 28, 91, 0.85)' : 'transparent';

    // Función para alternar entre abrir y cerrar el menú
    const handleToggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    // Función para cerrar el menú
    const handleCloseMenu = () => {
        setMenuOpen(false);
    };

    return (
        <>
            {/* Espacio para evitar que el contenido se solape con la barra de navegación */}
            <div style={{ height: scrolled ? '64px' : '0' }}></div>
            {/* Barra de navegación */}
            <nav className={`navbar text-white p-4 fixed w-full z-30 top-0 `} style={{ backgroundColor: bgColor }}>
                <div className="container mx-auto flex justify-between items-center">
                    <div className="flex items-center">
                        {/* Logo con enlace al inicio */}
                        <Link to={'/'}>
                            <img src={logo} alt="Logo" className="h-16 mr-2 cursor-pointer" />
                        </Link>
                    </div>
                    {/* Botón de menú para dispositivos móviles */}
                    <div className="lg:hidden">
                        <button onClick={handleToggleMenu} className="text-white focus:outline-none">
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                {/* Icono de hamburguesa o X según si el menú está abierto o cerrado */}
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />

                            </svg>
                        </button>
                    </div>
                    {/* Menú desplegable para dispositivos móviles */}
                    <div ref={menuRef} className={`lg:flex items-center space-x-4 text-xl h-40 lg:w-56 lg:flex-col lg:hidden ${menuOpen ? 'block' : 'hidden'}`} style={{ borderRadius: '10%', backgroundColor: 'rgba(0, 0, 0, 0.8)', position: 'absolute', top: '64px', left: '50%', transform: 'translateX(-50%)', width: 'calc(100% - 32px)' }}>
                        {/* Enlaces del menú */}
                        <div className="flex flex-col items-center pt-10">
                            <Link to={'/'} onClick={handleCloseMenu} className={`font-bold ${location.pathname === '/' ? 'text-blue-500' : 'text-white hover:text-gray-300 transition-colors duration-300'}`}>
                                Nosotros
                            </Link>
                            <Link to={'/servicios'} onClick={handleCloseMenu} className={`font-bold ${location.pathname === '/servicios' ? 'text-blue-500' : 'text-white hover:text-gray-300 transition-colors duration-300'}`}>
                                Servicios
                            </Link>
                            <Link to={"/contacto"} onClick={handleCloseMenu} className={`font-bold ${location.pathname === '/contacto' ? 'text-blue-500' : 'text-white hover:text-gray-300 transition-colors duration-300'}`}>
                                Contacto
                            </Link>
                        </div>
                        {/* Botón de cierre del menú */}
                        <button onClick={handleCloseMenu} className="text-white absolute top-2 right-2 focus:outline-none">
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    {/* Enlaces del menú para dispositivos de escritorio */}
                    <div className="hidden lg:flex items-center space-x-4 text-xl">
                        <Link to={'/'} className={`font-bold ${location.pathname === '/' ? 'text-blue-500' : 'text-white hover:text-gray-300 transition-colors duration-300'}`}>
                            Nosotros
                        </Link>
                        <Link to={'/servicios'} className={`font-bold ${location.pathname === '/servicios' ? 'text-blue-500' : 'text-white hover:text-gray-300 transition-colors duration-300'}`}>
                            Servicios
                        </Link>
                        <Link to={"/contacto"} className={`font-bold ${location.pathname === '/contacto' ? 'text-blue-500' : 'text-white hover:text-gray-300 transition-colors duration-300'}`}>
                            Contacto
                        </Link>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navbar;
