import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

const DropDownButton = ({ title, content }) => {
  // Estado para controlar si el contenido está abierto o cerrado
  const [isOpen, setIsOpen] = useState(false);

  // Animación para expandir y contraer el contenido
  const expandAnimation = useSpring({
    from: { opacity: 0, height: 0 }, // Estilo inicial de la animación (cerrado)
    to: { opacity: isOpen ? 1 : 0, height: isOpen ? 'auto' : 0 }, // Estilo final de la animación (abierto o cerrado)
  });

  return (
    <div className="my-4 w-60 text-center mx-auto"> {/* Asegúrate de que todos los botones tengan el mismo ancho */}
      {/* Botón que muestra el título y cambia el estado de isOpen al hacer clic */}
      <button
        className="text-lg font-semibold rounded-full outline outline-offset-2 outline-blue-500 bg-white text-blue-500 p-2 rounded w-full flex justify-center items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)} // Cambia el estado de isOpen al hacer clic
      >
        {title} {/* Título del botón */}
        <span className="ml-2">{isOpen ? '⬆️' : '⬇️'}</span> {/* Ícono para indicar si el contenido está abierto o cerrado */}
      </button>
      {/* Contenido que se expande o contrae con la animación */}
      <animated.div style={expandAnimation} className="overflow-hidden">
        <div className="p-2 bg-slate-300 border rounded shadow mt-2">
          {content} {/* Contenido que se mostrará cuando el botón esté abierto */}
        </div>
      </animated.div>
    </div>
  );
};

export default DropDownButton;
