import React from 'react';
import { motion } from 'framer-motion';
import DropDownButton from './DropDownButton'; // Importa el componente DropDownButton
import drop from '../img/drop.jpg'; // Importa la imagen 1
import dropp from '../img/dropp.jpg'; // Importa la imagen 2
import droppp from '../img/droppp.jpg'; // Importa la imagen 3

export const Empresa = () => {
  // Información de la empresa
  const empresaInfo = [
    {
      id: 1,
      image: drop,
      title: 'Quienes Somos',
      content: 'DS CYBER, es fundada con el propósito de apoyar a nuestros clientes en aumentar la productividad y reducir los riesgos de ciberseguridad. Inteligencia artificial, ciberseguridad y la consultoria tecnológica, cubiertos bajo el  enfoque de  la automatización es la ruta que ofrecemos para el éxito de nuestros clientes.'
    },
    {
      id: 2,
      image: dropp,
      title: 'Nuestro equipo',
      content: 'Nuestro equipo es el pilar fundamental para el logro de nuestros objetivos, contamos con mas de 20 años de experiencia en diferentes sectores de la economía: salud, educación, automotriz, finanzas, entre otros, que nos dan una visión amplia de como atender su requerimiento​.'
    },
    {
      id: 3,
      image: droppp,
      title: 'Nuestra razón de ser',
      content: 'Más productividad, menos riesgo. Acompañan nuestra misión de ofrecer servicios integrales que potencien la estrategia de nuestros clientes. Visionamos nuestra empresa como su asesor de confianza donde nos referencian con sus aliados y socios.​ '
    },
  ];

  // Variante de animación para los items de la empresa
  const itemVariants = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    hidden: { opacity: 0, y: 20 },
  };

  return (
    <div className="w-full text-center py-10 p-10">
      <h2 className="text-4xl font-bold mb-10">LA EMPRESA</h2>
      <div className='linea'></div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {empresaInfo.map((item, index) => (
          <motion.div 
            key={index} 
            className="flex flex-col items-center mb-8" 
            variants={itemVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            {/* Contenido de cada item */}
            <div className="w-64 h-64 overflow-hidden rounded-lg shadow-lg" style={{ boxShadow: '0 0 10px 6px rgba(0, 0, 0, 0.3)' }}>
              <img src={item.image} alt={item.title} className="w-full h-full object-cover" />
            </div>
            {/* Componente DropDownButton para mostrar el contenido */}
            <DropDownButton title={item.title} content={item.content} />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Empresa;
