// Certificado.jsx
import React, { useState, useEffect } from 'react';
import './Certificado.css';
import imagen from '../certificados/u7l6p0gme5ao.png';

import Alert from '@mui/material/Alert';

export const Certificado = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  const handleToggle = () => {
    // Solo permitimos expandir en dispositivos móviles
    if (window.innerWidth <= 768) {
      setIsExpanded(!isExpanded);
      if (isExpanded) {
        setShowAlert(false);
      }
    }
  };

  return (
    <div className={`certificado-container ${isExpanded ? 'expanded' : ''} bg-[#0e0f52] text-white`} onClick={handleToggle}>
      <h2 className="certificado-titulo">Certificado</h2>
      <img className="certificado-imagen" src={imagen} alt="Certificado" />
      {isExpanded && (
        <button className="close-button" onClick={handleToggle}>
          X
        </button>
      )}
      {showAlert && (
        <Alert severity="info">¡Aviso! si estas en celular Haz clic en el certificado para verlo más grande.</Alert>
      )}
    </div>
  );
};

export default Certificado;