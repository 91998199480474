import React from 'react';
import { Link } from 'react-router-dom';
import imagen1 from '../img/asistenteFinanciero.png'; // Importa la imagen 1
import imagen2 from '../img/callCenter.png'; // Importa la imagen 2
import imagen3 from '../img/Rh.png'; // Importa la imagen 3
import imagen4 from '../img/mesaDeAyuda.png'; // Importa la imagen 4

// Componente funcional Casos
function Casos() {
  return (
    <div className="container mx-auto py-8">
      {/* Título */}
      <h2 className="text-4xl font-bold text-center mb-4">CASOS DE USO</h2>
      <div className='linea'></div> {/* Línea decorativa */}
      <h2 className='text-center font-bold text-2xl'>INTELIGENCIA ARTIFICIAL GENERATIVA</h2>

      <div className="flex flex-wrap justify-center">
        {/* Tarjetas arriba */}
        <div className="w-full md:w-1/2 lg:w-1/4 p-4">
          <div className="card rounded-10">
            <div className="card-inner">
              <div className="front">
                {/* Imagen 1 */}
                <img src={imagen1} alt="Caso 1" className="w-full h-full object-cover m" />
              </div>
              <div className="back flex flex-col">
                <h2 className='text-2xl font-bold'>Asistente financiero</h2> {/* Título */}
                <p className="text-white text-center">Impulsa tu negocio con asistente virtual en solo 30 días.</p> {/* Descripción */}
                <Link to={'/consultas'}>
                  <button className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-blue-700 mt-5">
                    Conversemos
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <h3 className="text-center mt-2">Asistente financiero</h3> {/* Nombre de la tarjeta */}
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4 p-4">
          <div className="card rounded-10">
            <div className="card-inner">
              <div className="front">
                {/* Imagen 2 */}
                <img src={imagen2} alt="Caso 2" className="w-full h-full object-cover m" />
              </div>
              <div className="back flex flex-col">
                <h2 className='text-2xl font-bold'>Mesa de ayuda</h2> {/* Título */}
                <p className="text-white text-center">Experimenta la eficiencia en atención con IA, automatiza tu atención de requerimientos de TI.</p>
                <Link to={'/consultas'}>
                  <button className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-blue-700 mt-5">
                    Conversemos
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <h3 className="text-center mt-2">Mesa de ayuda</h3> {/* Nombre de la tarjeta */}
        </div>
      </div>
      <h2 className='text-center font-bold text-2xl'>INTELIGENCIA ARTIFICIAL APLICADA</h2>

      <div className="flex flex-wrap justify-center">

        {/* Tarjetas abajo */}
        <div className="w-full md:w-1/2 lg:w-1/4 p-4">
          <div className="card rounded-10">
            <div className="card-inner">
              <div className="front">
                {/* Imagen 3 */}
                <img src={imagen3} alt="Caso 3" className="w-full h-full object-cover m" />
              </div>
              <div className="back flex flex-col">
                <h2 className='text-2xl font-bold'>AI Document Intelligence</h2> {/* Título */}
                <p className="text-white text-center">Automatiza el proceso de extracción de información de documentos</p> {/* Descripción */}
                <Link to={'/consultas'}>
                  <button className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-blue-700 mt-5">
                    Conversemos
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <h3 className="text-center mt-2">AI Document Intelligence</h3> {/* Nombre de la tarjeta */}
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4 p-4">
          <div className="card rounded-10">
            <div className="card-inner">
              <div className="front">
                {/* Imagen 4 */}
                <img src={imagen4} alt="Caso 4" className="w-full h-full object-cover m" />
              </div>
              <div className="back flex flex-col">
                <h2 className='text-2xl font-bold'>Blackberry Cylance</h2> {/* Título */}
                <p className="text-white text-center">Solución de seguridad para endpoints que utiliza inteligencia artificial para prevenir y detectar ataques cibernéticos.</p> {/* Descripción */}
                <Link to={'/consultas'}>
                  <button className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-blue-700 mt-5">
                    Conversemos
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <h3 className="text-center mt-2">Blackberry Cylance</h3> {/* Nombre de la tarjeta */}
        </div>
      </div>
      {/* Botón de envío de solicitud */}
      <div className='w-screen flex justify-center'>
        <Link to={'/consultas'}>
          <button className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-blue-700">
            Envíanos tu solicitud
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Casos; // Exporta el componente Casos
