import React from 'react';
import { Fade } from 'react-awesome-reveal';
import img from '../img/ciberconciencia.png';
import imgg from '../img/dscop.png';
import imggg from '../img/validaciones.png';
import imgggg from '../img/proteccion.png';
import imggggg from '../img/threat.png';
import imgggggg from '../img/ciberinteligencia.png';

const data = [
  {
    imgSrc: img,
    title: 'Ciberconciencia',
    description: 'Las personas son el eslabón más débil de la empresa. Calcula y controla el riesgo de personas en ciberseguridad.',
  },
  {
    imgSrc: imgg,
    title: 'DSecOps',
    description: 'Visualiza la red, caza ciberamenazas en tiempo real, identifica los compromisos, responde automáticamente.',
  },
  {
    imgSrc: imggg,
    title: 'Validación de controles',
    description: 'Ejecuta campañas de red team y escenarios de purple team continua y automáticamente.',
  },
  {
    imgSrc: imgggg,
    title: 'Protección de datos',
    description: 'Proteja los datos críticos, enmascare u oculte la información sensible, simplifique el cumplimiento regulatorio.',
  },
  {
    imgSrc: imggggg,
    title: 'Threat Hunting',
    description: 'Hoy en día los hackers no buscan dañar tus equipos, quieren robar tu información y controlar tus equipos remotamente para diferentes fines, es por ello, que apoyamos a las empresas en la búsqueda de amenazas persistentes en su red.',
  },
  {
    imgSrc: imgggggg,
    title: 'Ciberinteligencia accionable',
    description: 'Cómo te ven los hackers desde Internet? ¿están tus cuentas comprometidas? ¿están los datos de tus clientes expuestos? Evalúa las vulnerabilidades de los sistemas, valida tu marca en la deepweb/Darkweb/openNet y toma acciones oportunas.',
  },
];

function Ciberseguridad() {
  return (
    <div className='bg-[#061C5B] h-auto w-screen text-center p-8 text-white' id="ciberseguridad">
      <h2 className='text-4xl font-bold'>CIBERSEGURIDAD</h2>
      <h3 className='text-3xl'>6 grandes áreas</h3>
      <div className='linea-dos mt-5'></div>
      {data.map((item, index) => (
        <div key={index} className='flex justify-center items-center my-6 custom-container'>
          {index % 2 === 0 ? (
            <>
              <Fade direction='left' triggerOnce>
                <div className='flex flex-col w-full p-4'>
                  <h3 className='text-4xl font-bold custom-text-center'>{item.title}</h3>
                  <p className='text-xl custom-text-center'>{item.description}</p>
                </div>
              </Fade>
              <Fade direction='right' triggerOnce>
                <div className='flex flex-col w-full p-4'>
                  <img className='custom-image rounded-2xl' src={item.imgSrc} alt={`Imagen ${index + 1}`} />
                </div>
              </Fade>
            </>
          ) : (
            <>
              <Fade direction='left' triggerOnce>
                <div className='flex flex-col w-full p-4'>
                  <img className='custom-image rounded-2xl' src={item.imgSrc} alt={`Imagen ${index + 1}`} />
                </div>
              </Fade>
              <Fade direction='right' triggerOnce>
                <div className='flex flex-col w-full p-4'>
                  <h3 className='text-4xl font-bold custom-text-center'>{item.title}</h3>
                  <p className='text-xl custom-text-center'>{item.description}</p>
                </div>
              </Fade>
            </>
          )}
        </div>
      ))}
    </div>
  );
}

export default Ciberseguridad;
